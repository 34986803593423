import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "@/constants";
import { HttpService } from "@/services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.LICENSE_SERVER_PREFIX}/user-panel/license-permit`;
const endpoints = {
    list: () => `${RESOURCE_ENDPOINT}`,
    history: () => `${RESOURCE_ENDPOINT}/history`,
    getById: (id: any) => `${RESOURCE_ENDPOINT}/${id}`,
    expiringSoon: (userId: any) => `${RESOURCE_ENDPOINT}/expiring-soon/${userId}`,
};

export default class LicensePermitsApi {
    public list = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.list();
        return HttpService.get(url, params, headers);
    };

    public history = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.history();
        return HttpService.get(url, params, headers);
    };

    public getById = (id: any): AxiosPromise<any> => {
        const url = endpoints.getById(id);
        return HttpService.get(url);
    };

    public expiringSoon = (userId: any = null): AxiosPromise<any> => {
        const url = endpoints.expiringSoon(userId);
        return HttpService.get(url);
    };
}
