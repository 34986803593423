import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.ACCOMMODATION_SERVER_PREFIX}/user-panel`;

const endpoints = {
    appliedServiceList: () => `${RESOURCE_ENDPOINT}/applied-service-list`,
    getDashboardData: () => `${RESOURCE_ENDPOINT}/dashboard-data`,
    getById: (id: any) => `${RESOURCE_ENDPOINT}/booked/${id}`,
    getPaymentAmountByApplicationId: (applicationId: any) => `${RESOURCE_ENDPOINT}/get-payment-amount/${applicationId}`,
    list: () => `${RESOURCE_ENDPOINT}/booking-list`,
    makePayment: () => `${RESOURCE_ENDPOINT}/make-payment`,
};

export default class UserPanelApi {
    public appliedServiceList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.appliedServiceList();
        return HttpService.get(url, params, headers);
    };

    public getDashboardData = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getDashboardData();
        return HttpService.get(url, params, headers);
    };

    public getById = (id: any): AxiosPromise<any> => {
        const url = endpoints.getById(id);
        return HttpService.get(url);
    };

    public getPaymentAmountByApplicationId = (applicationId: any): AxiosPromise<any> => {
        const url = endpoints.getPaymentAmountByApplicationId(applicationId);
        return HttpService.get(url);
    };

    public list = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.list();
        return HttpService.get(url, params, headers);
    };

    public makePayment = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.makePayment();
        return HttpService.post(url, payload, params, headers);
    };
}
