import React, {createContext, useReducer, useEffect   } from 'react'
import initialState from './auth.state';
import authReducer from './auth.reducer';
import {
  CLEAR_ALL_AUTH_STATE,
  LOADED_AUTH_STATE,
  LOADED_SCOPE,
  CLEAR_AUTH_STATE,
  LOADED_COMMITTEE,
  LOADED_LIBRARY,
  LOADED_LIBRARY_MEMBER, LOADED_INSTITUTION_HEAD
} from './auth.types';
import { HttpService } from "@/services/http.services";
import {CommitteeApi, OauthApi, LibraryApi, LibraryMemberDashboardApi, InstituteHeadApi} from "@/api";

export const AuthContext = createContext(initialState);

export const AuthProvider = ({ children }) => {
  const [state, dispatchAuth] = useReducer(authReducer, initialState);

  useEffect(() => {
    if (state.userId) {
      loadScopesInfo(state.userId)
      loadCommitteeInfo(state.userId);
      loadInstitutionHeadInfo(state.userId);
    }
    if (state.userId) {
        loadLibraryInfo(state.userId)
    }

  }, [state.userId, state.organogramId]);

  useEffect(() => {
 
    if (state.userId && state.libraryId) {
        loadLibraryMemberInfo(state.userId,state.libraryId)
    }

  }, [state.userId, state.libraryId]);

  const loadAuthState = async (token: string) => {
    try {
      HttpService.setAccessToken(token);

      OauthApi.loadAuthState()
          .then(res => {

            let isAdmin = false;
            let isUser = false;
            if (res.data.data.user_type === 'SERVICE_RECIPIENT') { // ROLE_USER
              isUser = true;
            }
            else {
              isAdmin = true;
            //   window.location.replace(`${SECURITY_UI_URL}/admin`);
            }

            // User
            dispatchAuth({
              type: LOADED_AUTH_STATE,
              payload: {
                isAuthenticated: true,
                isAdmin: isAdmin,
                isUser: isUser,

                user: res.data.data,
                userId: res.data.data.user_id ? Number(res.data.data.user_id) : null,
                userName: res.data.data.user_name,
                userEmail: res.data.data.user_email,
                userType: res.data.data.user_type,
                userNid: res.data.data.user_nid,
                isUserNidVerified: res.data.data.is_user_nid_verified,
                userNidVerificationAttempt: res.data.data.nid_verification_attempts,

                departmentId: res.data.data.department_id ? Number(res.data.data.department_id) : null,
                designationId: res.data.data.designation_id ? Number(res.data.data.designation_id) : null,

                profileId: res.data.data.profile_id ? Number(res.data.data.profile_id) : null,
                profileNameEn: res.data.data.profile_name_en,
                profileNameBn: res.data.data.profile_name_bn,
                profileType: res.data.data.profile_type,
                profileImage: res.data.data.profile_image,
                isProfileFilledUp: res.data.data.is_profile_filled_up,

                groupIds: res.data.data.group_ids?.map(item => Number(item)) || [],
                groupNameList: res.data.data.group_name_list,
                groupCodeList: res.data.data.group_code_list,

                roleIds: res.data.data.role_ids?.map(item => Number(item)) || [],
                roleNameList: res.data.data.role_name_list,
                roleCodeList: res.data.data.role_code_list,

                organogramId: res.data.data.organogram_id ? Number(res.data.data.organogram_id) : null,
                organogramIds: res.data.data.organogram_ids?.map(item => Number(item)) || [],
                organogramNameEn: res.data.data.organogram_name_en,
                organogramNameBn: res.data.data.organogram_name_bn,

                organizationId: res.data.data.organization_id ? Number(res.data.data.organization_id) : null,
                organizationIds: res.data.data.organization_ids?.map(item => Number(item)) || [],
                organizationNameEn: res.data.data.organization_name_en,
                organizationNameBn: res.data.data.organization_name_bn
              }
            });
          })
          .catch(err => {
            dispatchAuth({type: CLEAR_ALL_AUTH_STATE });
            dispatchAuth({type: CLEAR_AUTH_STATE });
          });
    }
    catch (err) {
      dispatchAuth({type: CLEAR_ALL_AUTH_STATE});
      dispatchAuth({type: CLEAR_AUTH_STATE});
    }
  }

  const loadScopesInfo = (userId: any) => {
    const payload = {
      'user_id': userId,
    }
    OauthApi.loadUserScopes(payload)
        .then(res => {
          dispatchAuth({
            type: LOADED_SCOPE,
            payload: {
              scopes: res.data.data,
            }
          });
        });
  }

  const loadCommitteeInfo = (userId: any) => {
    CommitteeApi.getCommitteeMemberByUserId(userId)
        .then(res => {
          dispatchAuth({
            type: LOADED_COMMITTEE,
            payload: {
              committeeIds: res.data.data.committeeIds,
            }
          });
        });
  }

  const loadInstitutionHeadInfo = (userId: any) => {
    InstituteHeadApi.getByUserId(userId)
        .then(res => {
          dispatchAuth({
            type: LOADED_INSTITUTION_HEAD,
            payload: {
              institutionHeadOrganizationId: res.data.data.institute_organization_id,
              institutionHeadDepartmentId: res.data.data.institute_organization_department_id,
              institutionHeadFacultyId: res.data.data.institute_organization_faculty_id
            }
          });
        });
  }

  const loadLibraryInfo = (memberUserId: any) => {
    const payload = {
      '$memberUserId': memberUserId
    };
    LibraryApi.getLibraryInfoByMemberUserId(payload)
        .then(res => {
          dispatchAuth({
            type: LOADED_LIBRARY,
            payload: {
              libraryId: res.data.data.id,
              libraryNameEn: res.data.data.name_en,
              libraryNameBn: res.data.data.name_bn,
              libraryShortName: res.data.data.short_name,
              libraryLogoImage: res.data.data.logo_image,
            }
          });
        });
  }

  const loadLibraryMemberInfo = (userId: any,libraryId: any) => {
    const payload = {
      '$library_id': libraryId,
      '$member_user_id': userId,
    };
    LibraryMemberDashboardApi.libraryMemberDashboardData(payload)
        .then(res => {
          dispatchAuth({
            type: LOADED_LIBRARY_MEMBER,
            payload: {
              libraryMemberId: res.data.data.member_id,
              libraryMemberNameEn: res.data.data.name_en,
              libraryMemberNameBn: res.data.data.name_bn,
            }
          });
     });
  }

  const clearAuthState = () => {
    dispatchAuth({type: CLEAR_ALL_AUTH_STATE});
    dispatchAuth({type: CLEAR_AUTH_STATE});
  }

  const setLoading = (isShow:boolean): void => {
    if(isShow){
      dispatchAuth({
        type: 'SET_LOADING',
        payload: {
          loading: true
        }
      });
    }
    else {
      dispatchAuth({
        type: 'SET_LOADING',
        payload: {
          loading: false
        }
      });
    }
  }

  const setUserNidVerified = (value:boolean): void => {
    dispatchAuth({
      type: 'SET_USER_NID_VERIFIED',
      payload: {
        isUserNidVerified: value
      }
    });
  }

  return (
      <AuthContext.Provider value={{ ...state, dispatchAuth, loadAuthState, clearAuthState, setLoading, setUserNidVerified}}>
        {children}
      </AuthContext.Provider>
  );
};

export default AuthProvider;
