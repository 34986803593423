import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.LIBRARY_SERVER_PREFIX}/opac`;
const endpoints = {
    libraryMemberDashboardData: () => `${RESOURCE_ENDPOINT}/library-member-dashboard`,
}

export default class LibraryMemberDashboardApi {
  public libraryMemberDashboardData = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.libraryMemberDashboardData();
    return HttpService.get(url, params, headers);
  }
}
