import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.LICENSE_SERVER_PREFIX}/user-panel/summary`;
const endpoints = {
    availedServices: () => `${RESOURCE_ENDPOINT}/availed-services`,
    applicationTracking: () => `${RESOURCE_ENDPOINT}/application-tracking`,
};

export default class UserApplicationCountApi {
    public availedServices = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.availedServices();
        return HttpService.post(url, payload, params, headers);
    };

    public applicationTracking = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.applicationTracking();
        return HttpService.post(url, payload, params, headers);
    };
}
