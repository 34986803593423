import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.GRANT_SERVER_PREFIX}/user-panel/summary`;
const endpoints = {
    formFillUpProgress: () => `${RESOURCE_ENDPOINT}/form-fill-up-progress`,
    applicationTracking: () => `${RESOURCE_ENDPOINT}/application-tracking`,
    progressReportSummery: () => `${RESOURCE_ENDPOINT}/progress-report`,
    billSubmissionSummery: () => `${RESOURCE_ENDPOINT}/bill-information`,
};

export default class UserPanelSummaryApi {
    public formFillUpProgress = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.formFillUpProgress();
        return HttpService.post(url, payload, params, headers);
    }

    public applicationTracking = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.applicationTracking();
        return HttpService.post(url, payload, params, headers);
    }

    public progressReportSummery = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.progressReportSummery();
        return HttpService.post(url, payload, params, headers);
    }

    public billSubmissionSummery = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.billSubmissionSummery();
        return HttpService.post(url, payload, params, headers);
    }
}
