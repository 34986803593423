import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.RESEARCH_SERVER_PREFIX}/research-user-dashboard`;
const endpoints = {
    getResearchWiseDataCount: () => `${RESOURCE_ENDPOINT}/research-user-data`,
}

export default class UserDashboardApi {

  public getResearchWiseDataCount = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.getResearchWiseDataCount();
    return HttpService.get(url, params, headers);
  }
}
