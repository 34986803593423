import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.TRAINING_SERVER_PREFIX}/user-panel/summary`;
const endpoints = {
    todayOlympiadQuizServiece: () => `${RESOURCE_ENDPOINT}/today-olympiad-quiz`,
    applicationTracking: () => `${RESOURCE_ENDPOINT}/lab-visit-application-tracking`,
    appliedCourseCountTracking: () => `${RESOURCE_ENDPOINT}/applied-course-tracking`,
    appliedServiceList: () => `${RESOURCE_ENDPOINT}/applied-service-list`,
    applicationChartData: () => `${RESOURCE_ENDPOINT}/applied-service-chart-data`,
};

export default class UserDashboardServiceApi {
    public todayOlympiadQuizServiece = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.todayOlympiadQuizServiece();
        return HttpService.post(url, payload, params, headers);
    };

    public applicationTracking = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.applicationTracking();
        return HttpService.post(url, payload, params, headers);
    };

    public appliedCourseCountTracking = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.appliedCourseCountTracking();
        return HttpService.post(url, payload, params, headers);
    };

    public appliedTrainingServiceList = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.appliedServiceList();
        return HttpService.post(url, payload, params, headers);
    };

    public applicationChartData = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.applicationChartData();
        return HttpService.post(url, payload, params, headers);
    };
}
