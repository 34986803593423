import {
  SET_LOADING,
  SET_ERROR,
  LOADED_SCOPE,
  SET_USER_NID_VERIFIED,
  CLEAR_ALL_AUTH_STATE,
  LOADED_AUTH_STATE,
  CLEAR_AUTH_STATE,
  LOADED_TOKEN,
  REMOVE_TOKEN,
  LOADED_COMMITTEE,
  LOADED_LIBRARY,
  LOADED_LIBRARY_MEMBER,
  LOADED_INSTITUTION_HEAD,
} from './auth.types';
import {StorageService} from "../../services";

export default (state, action) => {
  switch (action.type) {
    case LOADED_AUTH_STATE:
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        isAdmin: action.payload.isAdmin,
        isUser: action.payload.isUser,
        user: action.payload.user,
        userId: action.payload.userId,
        userName: action.payload.userName,
        userEmail: action.payload.userEmail,
        userType: action.payload.userType,
        userNid: action.payload.userNid,
        isUserNidVerified: action.payload.isUserNidVerified,
        userNidVerificationAttempt: action.payload.userNidVerificationAttempt,
        profileId: action.payload.profileId,
        isProfileFilledUp: action.payload.isProfileFilledUp,
        profileNameEn: action.payload.profileNameEn,
        profileNameBn: action.payload.profileNameBn,
        profileType: action.payload.profileType,
        profileImage: action.payload.profileImage,
        departmentId: action.payload.departmentId,
        designationId: action.payload.designationId,
        groupIds: action.payload.groupIds,
        groupNameList: action.payload.groupNameList,
        groupCodeList: action.payload.groupCodeList,
        roleIds: action.payload.roleIds,
        roleNameList: action.payload.roleNameList,
        roleCodeList: action.payload.roleCodeList,
        committeeId: action.payload.committeeId,
        committeeName: action.payload.committeeName,
        committeeType: action.payload.committeeType,
        organogramId: action.payload.organogramId,
        organogramIds: action.payload.organogramIds,
        organogramNameEn: action.payload.organogramNameEn,
        organogramNameBn: action.payload.organogramNameBn,
        organizationId: action.payload.organizationId,
        organizationIds: action.payload.organizationIds,
        organizationNameEn: action.payload.organizationNameEn,
        organizationNameBn: action.payload.organizationNameBn,
        loading: false,
        error: false,
      };

    case LOADED_COMMITTEE:
      return {
        ...state,
        committeeIds: action.payload.committeeIds,
      };

    case LOADED_INSTITUTION_HEAD:
      return {
        ...state,
        institutionHeadOrganizationId: action.payload.institutionHeadOrganizationId,
        institutionHeadDepartmentId: action.payload.institutionHeadDepartmentId,
        institutionHeadFacultyId: action.payload.institutionHeadFacultyId,
      };

    case LOADED_SCOPE:
      return {
        ...state,
        scopes: action.payload.scopes,
      };

    case LOADED_LIBRARY:
      return {
        ...state,
        libraryId: action.payload.libraryId,
        libraryNameEn: action.payload.libraryNameEn,
        libraryNameBn: action.payload.libraryNameBn,
        libraryShortName: action.payload.libraryShortName,
        libraryLogoImage: action.payload.libraryLogoImage,
      };

      case LOADED_LIBRARY_MEMBER:
        return {
          ...state,
          libraryMemberId: action.payload.libraryMemberId,
          libraryMemberNameEn: action.payload.libraryMemberNameEn,
          libraryMemberNameBn: action.payload.libraryMemberNameBn,

        };

    case LOADED_TOKEN:
      StorageService.setAccessToken(action.payload.accessToken);
      StorageService.setRefreshToken(action.payload.refreshToken);
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
      };

    case REMOVE_TOKEN:
      StorageService.removeAccessToken();
      StorageService.removeRefreshToken();
      return {
        ...state,
        isAuthenticated: false,
        isAdmin: false,
        isUser: false,
        accessToken: null,
        refreshToken: null,
      };

    case CLEAR_AUTH_STATE:
      return {
        ...state,
        isAuthenticated: false,
        isAdmin: false,
        isUser: false,
        user: null,
        userId: null,
        scopes: null,
        loading: false,
        error: null,
      };

    case CLEAR_ALL_AUTH_STATE:
      return {
        ...state,
        isAuthenticated: false,
        isAdmin: false,
        isUser: false,
        user: null,
        userId: null,
		userName: null,
		userEmail: null,
        userType: null,
        userNid: null,
        isUserNidVerified: false,
        userNidVerificationAttempt: 0,
		profileId: null,
        profileNameEn: null,
        profileNameBn: null,
        profileType: null,
        profileImage: null,
        departmentId: null,
        designationId: null,
        groupIds: null,
        groupNameList: null,
        groupCodeList: null,
        committeeId: null,
        committeeName: null,
        committeeType: null,
        roleIds: null,
        roleNameList: null,
        roleCodeList: null,
        organogramId: null,
        organogramIds: null,
        organogramNameEn: null,
        organogramNameBn: null,
        organizationId: null,
        organizationIds: null,
        organizationNameEn: null,
        organizationNameBn: null,
        scopes: null,
        loading: false,
        error: null,
      };

    case SET_LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      };

    case SET_USER_NID_VERIFIED:
      return {
        ...state,
        isUserNidVerified: action.payload.isUserNidVerified,
      };

    case SET_ERROR:
      return {
        ...state,
        error: action.payload.error,
      };

    default:
      return state;
  }
};
