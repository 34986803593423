export const SET_ERROR = 'SET_ERROR';
export const SET_LOADING = 'SET_LOADING';
export const SET_USER_NID_VERIFIED = 'SET_USER_NID_VERIFIED';
export const LOADED_SCOPE = 'LOADED_SCOPE';
export const LOADED_COMMITTEE = 'LOADED_COMMITTEE';
export const LOADED_INSTITUTION_HEAD = 'LOADED_INSTITUTION_HEAD';
export const LOADED_TOKEN = 'LOADED_TOKEN';
export const REMOVE_TOKEN = 'REMOVE_TOKEN';
export const LOADED_AUTH_STATE = 'LOADED_AUTH_STATE';
export const CLEAR_AUTH_STATE = 'CLEAR_AUTH_STATE';
export const CLEAR_ALL_AUTH_STATE = 'CLEAR_ALL_AUTH_STATE';
export const LOADED_LIBRARY = 'LOADED_LIBRARY';
export const LOADED_LIBRARY_MEMBER = 'LOADED_LIBRARY_MEMBER';
