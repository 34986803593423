import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.GRANT_SERVER_PREFIX}/bill-submission`
const endpoints = {
    list: () => `${RESOURCE_ENDPOINT}`,
    getById: (id: any) => `${RESOURCE_ENDPOINT}/${id}`,
    getByWhere: () => `${RESOURCE_ENDPOINT}/getByWhere`,
    create: () => `${RESOURCE_ENDPOINT}`,
    update: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
    updatePartial: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
    delete: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
    bulk: () => `${RESOURCE_ENDPOINT}/bulk`,
    dropdown: () => `${RESOURCE_ENDPOINT}/dropdown`,
    userWiseBillList: () => `${RESOURCE_ENDPOINT}/user-wise-bill-list`,
    deptHeadWiseBillSubmissionList: () => `${RESOURCE_ENDPOINT}/dept-head-wise-bill-submission-list`,
    recommendByInstituteHead: () => `${RESOURCE_ENDPOINT}/dept-head-recommendation`,
    getSRGTotalBill: () => `${RESOURCE_ENDPOINT}/getSRG-bill`,
    getPreviousBill: (finalSelectionId: any, billId: any) => `${RESOURCE_ENDPOINT}/previous-bill/${finalSelectionId}/${billId}`,
}

export default class BillSubmissionApi {
    public list = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.list();

        return HttpService.get(url, params, headers);
    }

    public getById = (id: any): AxiosPromise<any> => {
        const url = endpoints.getById(id);
        return HttpService.get(url);
    }

    public getByWhere = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getByWhere();
        return HttpService.get(url, params, headers);
      }

    public create = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.create();
        return HttpService.post(url, payload, params, headers);
    }
    public recommendByInstituteHead = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.recommendByInstituteHead();
        return HttpService.post(url, payload, params, headers);
    }

    public getSRGTotalBill = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getSRGTotalBill();
        return HttpService.post(url, payload, params, headers);
    }

    public update = (id: any, payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.update(id);
        return HttpService.put(url, payload, params, headers);
    }

    public getPreviousBill = (finalSelectionId: any, billId?:any): AxiosPromise<any> => {
        const url = endpoints.getPreviousBill(finalSelectionId, billId);
        return HttpService.get(url);
    };

    public updatePartial = (id: any, payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.updatePartial(id);
        return HttpService.patch(url, payload, params, headers);
    }

    public delete = (id: any, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.delete(id);
        return HttpService.delete(url, params, headers);
    }

    public bulk = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.bulk();
        return HttpService.post(url, payload, params, headers);
    }

    public dropdown = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.dropdown();
        return HttpService.get(url, params, headers);
    }

    public userWiseBillList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.userWiseBillList();

        return HttpService.get(url, params, headers);
    }
    public deptHeadWiseBillSubmissionList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.deptHeadWiseBillSubmissionList();

        return HttpService.get(url, params, headers);
    }
}
