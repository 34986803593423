import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../constants";
import { HttpService } from "@/services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.HEALTH_SERVER_PREFIX}/dashboard`;
const endpoints = {
    getDashboardData: () => `${RESOURCE_ENDPOINT}/user-dashboard-data`,

}

export default class UserDashboardApi {
    public getDashboardData = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getDashboardData();
        return HttpService.get(url, params, headers);
    }
}
