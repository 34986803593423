import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.LICENSE_SERVER_PREFIX}/user-panel`;
const endpoints = {
    appliedServiceList: () => `${RESOURCE_ENDPOINT}/applied-service-list`,
};

export default class UserPanelApi {
    public appliedServiceList = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.appliedServiceList();
        return HttpService.post(url, payload, params, headers);
    };
}
