export enum YesNoEnum {
    Yes = 1,
    No = 0,
}

export enum NoYesEnum {
    No = 0,
    Yes = 1,
}

export enum PricingType {
    Fixed = 'Fixed',
    UnitRange = 'Unit Range',
    CNFValueRange = 'C&F Value Range',
    CallForPrice = 'Call For Price'
}


export enum RegistrationType {
    Individual = 1,
    'Institute/Organization' = 2,
}
