// CDN Server
import _FileApi from "./Cdn/File.api";
import _CsvApi from "./Cdn/Csv.api";

// Auth Server
import _GroupApi from "./Auth/Group.api";
import _OauthApi from "./Auth/Oauth.api";
import _PermissionApi from "./Auth/Permission.api";
import _ResourceApi from "./Auth/Resource.api";
import _RoleApi from "./Auth/Role.api";
import _ScopeApi from "./Auth/Scope.api";
import _UserApi from "./Auth/User.api";
import _WorkspaceApi from "./Auth/Workspace.api";
import _OrganizationApi from "./Auth/Organization.api";
import _OrganogramApi from "./Auth/Organogram.api";
import _EmployeeApi from "./Auth/Employee.api";
import _RequestAccessApi from "./Auth/RequestAccess.api";
import _InstituteHeadApi from "./Auth/InstituteHead.api";
import _DesignationApi from "./Auth/Designation.api";
import _UserNidDetailApi from "./Auth/UserNidDetail.api";

// Core Server
import _LookupApi from "./Core/Lookup.api";
import _ComponentApi from "./Core/Component.api";
import _LocationApi from "./Core/Location.api";
import _PostApi from "./Core/Post.api";
import _OptionApi from "./Core/Option.api";
import _HelpApi from "./Core/Help.api";
import _ActivityLogApi from "./Util/ActivityLog.api";
import _AuditLogApi from "./Util/AuditLog.api";
import _ExportScmApi from "./Core/ExportScm.api";
import _ImportScmApi from "./Core/ImportScm.api";
import _FackApi from "./Core/Fack.api";
import _OrganizationCategoryApi from "./Core/OrganizationCategory.api";
import _DepartmentApi from "./Core/Department.api";
import _ApiResourceApi from "./Core/ApiResource.api";
import _FinancialYearApi from "./Core/FinancialYear.api";
import _EnumApi from "./Core/Enum.api";
import _BankApi from "./Core/Bank.api";
import _BankBranchApi from "./Core/BankBranch.api";
import _DepartmentSubjectApi from "./Core/DepartmentSubject.api";
import _FacultySchoolApi from "./Core/FacultySchool.api";
import _InstituteOrganizationApi from "./Core/InstituteOrganization.api";
import _AutomationRuleApi from "./Core/AutomationRule.api";
import _WorkflowApprovalApi from "./Core/WorkflowApproval.api";
import _WorkflowStepApi from "./Core/WorkflowStep.api";
import _WorkflowTransitionApi from "./Core/WorkflowTransition.api";
import _WorkflowBulkTransitionApi from "./Core/WorkflowBulkTransition.api";
import _WorkflowTransitionAssignmentApi from "./Core/WorkflowTransitionAssignment.api";
import _ServiceMappingApi from "./Core/ServiceMapping.api";
import _OwnerShipTypeApi from "./Core/OwnerShipType.api";
import _PaymentApi from "./Core/Payment.api";
import _CorePaymentHistoryApi from "./Core/PaymentHistory.api";
import _EducationalInstituteApi from "./Core/EducationalInstitute.api";
// Util Server
import _NotificationApi from "./Util/Notification.api";

// Grant Server
import _FellowshipTypeApi from "./Grant/FellowshipType.api";
import _FellowshipAllowanceHeadApi from "./Grant/FellowshipAllowanceHead.api";
import _FellowshipAllowanceRateApi from "./Grant/FellowshipAllowanceRate.api";
import _FellowshipGroupApi from "./Grant/FellowshipGroup.api";
import _StudyTypeNameApi from "./Grant/StudyTypeName.api";
import _CircularApi from "./Grant/Circular.api";
import _EligibleCriteriaApi from "./Grant/EligibleCriteria.api";
import _SelectionCriteriaApi from "./Grant/SelectionCriteria.api";
import _ApplicationTypeApi from "./Grant/ApplicationType.api";
import _ItemTypeApi from "./Grant/ItemType.api";
import _StudyTypeApi from "./Grant/StudyType.api";
import _AttachmentTypeApi from "./Core/AttachmentType.api";
import _ApplicationFeeApi from "./Grant/ApplicationFee.api";
import _MarkDistributionApi from "./Grant/MarkDistribution.api";
import _CommitteeApi from "./Grant/Committee.api";
import _CommitteeMemberInfoApi from "./Grant/CommitteeMemberInfo.api";
import _FellowshipCategoryApi from "./Grant/FellowshipCategory.api";
import _PriorityProjectApi from "./Grant/PriorityProject.api";
import _SpecialAllocationRateApi from "./Grant/SpecialAllocationRate.api";
import _RAndDProjectApi from "./Grant/RAndDProject.api";
import _InternshipThesisApplicationApi from "./Grant/InternshipThesisApplication.api";
import _SpecialResearchGrantApplicationApi from "./Grant/SpecialResearchGrantApplication.api";
import _ExportGrantApi from "./Grant/ExportGrant.api";
import _ImportGrantApi from "./Grant/ImportGrant.api";
import _ReportGrantApi from "./Grant/ReportGrant.api";
import _FinalSelectionApi from "./Grant/FinalSelection.api";
import _ProgressReportApi from "./Grant/ProgressReport.api";
import _BillSubmissionApi from "./Grant/BillSubmission.api";
import _ResearcherProfileApi from "./Grant/ResearcherProfile.api";
import _ResearcherConsentApi from "./Grant/ResearcherConsent.api";
import _DepartmentHeadRecommendationApi from "./Grant/DepartmentHeadRecommendation.api";
import _ExamApi from "./Grant/Exam.api";
import _AIResearcherChangeRequestApi from "./Grant/AIResearcherChangeRequest.api";
import _AgreementSignApi from "./Grant/AgreementSign.api";
import _FellowshipCancellationApi from "./Grant/FellowshipCancellation.api";
import _SponsorshipLetterApi from "./Grant/SponsorshipLetter.api";
import _WorkflowTransitionGrantApi from "./Grant/WorkflowTransitionGrant.api";
import _SynopsisOfResearchProjectApi from "./Grant/SynopsisOfResearchProject.api";
import _SynopsisOfResearchProjectRnDMoSTApi from "./Grant/SynopsisOfResearchProjectRnDMoST.api";
import _PreviousApprovedProjectApi from "./Grant/PreviousApprovedProject.api";
import _RnDPreviousProjectApi from "./Grant/RnDPreviousProject.api";

// Research
import _JournalArticleApi from "./Research/JournalArticleApi.api";
import _JournalArticleApplicationApi from "./Research/JournalArticleApplication.api";
import _ResearcherNetworkApi from "./Research/ResearcherNetwork.api";
import _WebinarExpressionOfInterestApi from "./Research/WebinarExpressionOfInterest.api";
import _WebinarRegistrationApi from "./Research/WebinarRegistration.api";
import _ResearchFeildApi from "./Research/ResearchFeild.api";
import _ResearchLevelApi from "./Research/ResearchLevel.api";
import _JournalArticleApplicationInfoApi from "./Research/JournalArticleApplicationInfo.api";
import _ResearchProfileApi from "./Research/ResearchProfile.api";
import _UserDashboardApi from "./Research/UserDashboard.api";
import _JournalNameApi from "./Research/JournalNameApi.api";

import _ApplicationApi from "./Grant/Application.api";
import _BSTFTApplicationApi from "./Grant/BSTFTApplication.api";
import _NSTFellowshipApplicationApi from "./Grant/NSTFellowshipApplication.api";
import _ApplicantProfileApi from "./Grant/ApplicantProfile.api";
import _ApplicantProfileEducationalQualificationApi from "./Grant/ApplicantProfileEducationalQualification.api";
import _ApplicantProfilePreviousExperienceApi from "./Grant/ApplicantProfilePreviousExperience.api";
import _ApplicantProfileAwardsScholarshipApi from "./Grant/ApplicantProfileAwardsScholarship.api";
import _ApplicantProfilePublicationApi from "./Grant/ApplicantProfilePublication.api";
import _ApplicantProfileTrainingSeminarCourseApi from "./Grant/ApplicantProfileTrainingSeminarCourse.api";
import _ApplicantProfileAttachmentApi from "./Grant/ApplicantProfileAttachment.api";


import _CommitteeTypeApi from "./Grant/CommitteeType.api";
import _ResearchCategoryAreaApi from "./Grant/ResearchCategoryArea.api";
import _ScientificOrgApi from "./Grant/ScientificOrg.api";
import _NonGovtInstituteApplicationApi from "./Grant/NonGovtInstituteApplication.api";
import _RAndDMOSTApi from "./Grant/RAndDMOST.api";
import _ResearchFellowshipApplicationApi from "./Grant/ResearchFellowshipApplication.api";
import _UserApplicationApi from "./Grant/UserApplication.api";
import _UserPanelApi from "./Grant/UserPanel.api";
import _UserPanelSummaryApi from "./Grant/UserPanelSummary.api";
import _UserApplicationBillInfoApi from "./Grant/UserApplicationBillInfo.api";
import _BillCirculationApi from "./Grant/BillCirculation.api";
import _CompletionCertificateApi from "./Grant/CompletionCertificate.api";

// License.
import _LicenseUserApplicationApi from "./License/LicenseUserApplication.api";
import _ScienceClubApplicationApi from "./License/ScienceClubApplication.api";
import _SurrenderTransferApplicationApi from "./License/SurrenderTransferApplication.api";
import _ImportExportPermitApi from "./License/ImportExportPermit.api";
import _LicenseApplicationAApi from "./License/LicenseApplicationA.api";
import _LicenseApplicationCApi from "./License/LicenseApplicationC.api";
import _LicenseApplicationEApi from "./License/LicenseApplicationE.api";
import _LicenseApplicationRcoApi from "./License/LicenseApplicationRco.api";
import _LicenseNocApplicationApi from "./License/NocApplicationApi.api";
import _UserApplicationCountApi from "./License/UserApplicationCount.api";
import _LicenseUserPanelApi from "./License/UserPanel.api";
import _LicensePermitsApi from "./License/LicensePermits.api";
import _RcoAnnualReportApi from "./License/RcoAnnualReportApi.api";
import _LicenseApplicationDApi from "./License/LicenseApplicationD.api";
import _LicenseFeeApi from "./License/LicenseFee.api";

// CMS Server
import _PageApi from "./Cms/Page.api";
import _BlocksApi from "./Cms/Blocks.api";
import _MenuApi from "./Cms/Menu.api";
import _ServicesApi from "./Cms/Services.api";
import _MediaApi from "./Cms/Media.api";
import _CategoriesApi from "./Cms/Categories.api";

// Library
import _LibraryApi from "./Library/Library.api";
import _LibraryMemberRegistrationApi from "./Library/LibraryMemberRegistration.api";
import _LibraryBookReservationApi from "./Library/LibraryBookReservationApi.api";
import _LibraryMemberDashboardApi from "./Library/LibraryMemberDashboard.api";
import _BookIssueApi from "./Library/BookIssue.api";
import _LibraryMemberReminderApi from "./Library/LibraryMemberReminderApi.api";

// Process Lease
import _ProcessLeaseApplicationApi from "./ProcessLease/ProcessLeaseApplication.api";
import _RequestApplicationApi from "./ProcessLease/RequestApplication.api";
import _TemplatesApi from "./ProcessLease/Templates.api";
import _LetterApi from "./ProcessLease/Letter.api";
import _PaymentLeaseApi from "./ProcessLease/Payment.api";
import _PaymentHistoryApi from "./ProcessLease/PaymentHistory.api";
import _PaymentPremiumFeeApi from "./ProcessLease/PaymentPremiumFee.api";
import _PaymentAnnulFeeApi from "./ProcessLease/PaymentAnnulFee.api";
import _PaymentAllFeesApi from "./ProcessLease/PaymentAllFees.api";
import _PaymentInformationApi from "./ProcessLease/PaymentInformation.api";
import _ApplicationListInformationApi from "./ProcessLease/ApplicationListInformation.api";
import _ProcessItemApi from "./ProcessLease/ProcessItem.api";
import _NoteApi from "./ProcessLease/Note.api";

// TestConsultancy
import _ExportTestConsultancyApi from "./TestConsultancy/ExportTestConsultancy.api";
import _ImportTestConsultancyApi from "./TestConsultancy/ImportTestConsultancy.api";
import _InventoryApi from "./TestConsultancy/Inventory.api";
import _TemplateApi from "./TestConsultancy/Template.api";
import _ServiceApplicationApi from "./TestConsultancy/ServiceApplication.api";
import _AttributeApi from "./TestConsultancy/Attribute.api";
import _AttributeItemApi from "./TestConsultancy/AttributeItem.api";
import _TestServiceMapApi from "./TestConsultancy/TestServiceMap.api";
import _VatAitApi from "./TestConsultancy/VatAit.api";
import _InvoiceApi from "./TestConsultancy/Invoice.api";
import _IssueLetterApi from "./TestConsultancy/IssueLetter.api";
import _MOUApi from "./TestConsultancy/MOU.api";
import _ReportPreparationApi from "./TestConsultancy/ReportPreparation.api";
import _ReportDeliveryApi from "./TestConsultancy/ReportDelivery.api";
import _TestConsultancyReportApi from "./TestConsultancy/TestConsultancyReportApi.api";
import _RefundApi from "./TestConsultancy/Refund.api";
import _MethodologyApi from "./TestConsultancy/Methodology.api";
import _CategoryApi from "./TestConsultancy/Category.api";
import _SubCategoryApi from "./TestConsultancy/SubCategory.api";
import _ReceiveLetterApi from "./TestConsultancy/ReceiveLetter.api";
import _FeeCollectionHistoryApi from "./TestConsultancy/FeeCollectionHistory.api";
import _FeeCollectionApplicationFeeApi from "./TestConsultancy/FeeCollectionApplicationFee.api";
import _FeeCollectionAllFeesApi from "./TestConsultancy/FeeCollectionAllFees.api";
import _FeeCollectionInformationApi from "./TestConsultancy/FeeCollectionInformation.api";
import _ServiceApplicationListInformationApi from "./TestConsultancy/ServiceApplicationListInformation.api";
import _UserPanelApplicationApi from "./TestConsultancy/UserPanelApplication.api";

// Health
import _DoctorAppointmentApi from "./Health/DoctorAppointment.api";
import _TestingApplicationApi from "./Health/TestingApplication.api";
import _RadiotherapyPrescriptionApi from "./Health/RadiotherapyPrescription.api";
import _TestPaymentApi from "./Health/TestPaymentapi";
import _TestApplicationPaymentHistoryApi from "./Health/TestApplicationPaymentHistory.api";
import _HealthApplicationApi from "./Health/Application.api";
import _HealthUserDashboardApi from "./Health/UserDashboard.api";
import _HealthServiceApplicationApi from "./Health/HealthServiceApplication.api";
import _HealthReportPreparationApi from "./Health/HealthReportPreparation.api";

// eTicket
import _ProgramApi from "./Eticket/Program.api";
import _ProgramTypeApi from "./Eticket/ProgramType.api";
import _TicketRequestApi from "./Eticket/TicketRequest.api";
import _CounterSalesApi from "./Eticket/CounterSales.api";
import _SeatPlanApi from "./Eticket/SeatPlan.api";
import _SeatLayoutApi from "./Eticket/SeatLayout.api";
import _HallSetupApi from "./Eticket/HallSetup.api";
import _ProgramScheduleApi from "./Eticket/ProgramSchedule.api";
import _PackageApi from "./Eticket/Package.api";
import _PackageItemApi from "./Eticket/packageItem.api";
import _TicketSaleApi from "./Eticket/TicketSale.api";
import _EticketUserDashboardApi from "./Eticket/EticketUserDashboard.api";
import _ScienceBusApi from "./Eticket/ScienceBus.api";
import _TransportApi from "./Eticket/Transport.api";

// Training
import _ExportTrainingApi from "./Training/ExportTraining.api";
import _ImportTrainingApi from "./Training/ImportTraining.api";
import _TrainingCategoryApi from "./Training/TrainingCategory.api";
import _TrainingSubjectApi from "./Training/TrainingSubject.api";
import _TrainingTitleApi from "./Training/TrainingTitle.api";
import _TrainerProfileApi from "./Training/TrainerProfile.api";
import _InternalTrainerUserApi from "./Training/InternalTrainerUser.api";
import _EOISubmissionApi from "./Training/EOISubmission.api"
import _RegistrationInCourseApi from "./Training/RegistrationInCourseApi.api"
import _RegistrationInECourseApi from "./Training/RegistrationInECourseApi.api"
import _TrainingCourseScheduleApi from "./Training/TrainingCourseScheduleApi.api"
import _TraineeQuizQuestionApi from "./Training/TraineeQuizQuestion.api"
import _TraineeQuizApi from "./Training/TraineeQuiz.api"
import _TraineeExamMarksApi from "./Training/TraineeExamMarks.api"
import _OlympiadQuizExamAndResultApi from "./Training/OlympiadQuizExamAndResultApi.api"
import _OlympiadQuizExamAnswerDetailsApi from "./Training/OlympiadQuizExamAnswerDetailsApi.api"
import _TrainingCourseScheduleDetailApi from "./Training/TrainingCourseScheduleDetailApi.api"
import _TrainingCourseScheduleExamProcessApi from "./Training/TrainingCourseScheduleExamProcessApi.api"
import _TrainerEvaluationSetupApi from "./Training/TrainerEvaluationSetup.api"
import _EvaluateTrainerByTraineeApi from "./Training/EvaluateTrainerByTrainee.api"
import _OlympiadQuizApi from "./Training/OlympiadQuizApi.api"
import _LabTrainingVisitApplicationApi from "./Training/LabTrainingVisitApplicationApi.api"
import _ECourseAssesmentsApi from "./Training/ECourseAssesments.api"
import _ECourseAssesmentQuestionApi from "./Training/ECourseAssesmentQuestion.api"
import _ECourseApi from "./Training/ECourse.api"
import _ECourseAssesmentExamMarksApi from "./Training/ECourseAssesmentExamMarks.api"

import _UserDashboardServiceApi from "./Training/UserDashboardServiceApi.api"
import _OlympiadQuizAttendanceApi from "./Training/OlympiadQuizAttendanceApi.api"
import _RegistrationInRCOExamCircularApi from "./Training/RegistrationInRCOExamCircularApi.api"

// ACCOMMODATION
import _AllotmentApplicationApi from "./Accommodation/AllotmentApplication.api";
import _AccommodationTypeApi from "./Accommodation/AccommodationType.api";
import _CommonConfigurationTypeApi from "./Accommodation/CommonConfigurationType.api";
import _AccommodationUserInvoiceApi from "./Accommodation/AccommodationUserInvoice.api";
import _AccommodationPaymentApi from "./Accommodation/AccommodationPayment.api";
import _AccommodationUserPanelApi from "./Accommodation/UserPanel.api";
import _ApplyDiscountApi from "./Accommodation/ApplyDiscountApi.api";

// CDN Server
export const FileApi = new _FileApi();
export const CsvApi = new _CsvApi();

// Auth Server
export const GroupApi = new _GroupApi();
export const OauthApi = new _OauthApi();
export const PermissionApi = new _PermissionApi();
export const ResourceApi = new _ResourceApi();
export const RoleApi = new _RoleApi();
export const ScopeApi = new _ScopeApi();
export const UserApi = new _UserApi();
export const WorkspaceApi = new _WorkspaceApi();
export const OrganizationApi = new _OrganizationApi();
export const ServiceApi = new _ServicesApi();
export const OrganogramApi = new _OrganogramApi();
export const EmployeeApi = new _EmployeeApi();
export const RequestAccessApi = new _RequestAccessApi();
export const InstituteHeadApi = new _InstituteHeadApi();
export const LicenseFeeApi = new _LicenseFeeApi();

// Core Server
export const FackApi = new _FackApi();
export const ComponentApi = new _ComponentApi();
export const LookupApi = new _LookupApi();
export const LocationApi = new _LocationApi();
export const PostApi = new _PostApi();
export const OptionApi = new _OptionApi();
export const HelpApi = new _HelpApi();
export const ActivityLogApi = new _ActivityLogApi();
export const AuditLogApi = new _AuditLogApi();
export const ExportScmApi = new _ExportScmApi();
export const ImportScmApi = new _ImportScmApi();
export const OrganizationCategoryApi = new _OrganizationCategoryApi();
export const DepartmentApi = new _DepartmentApi();
export const ApiResourceApi = new _ApiResourceApi();
export const FinancialYearApi = new _FinancialYearApi();
export const EnumApi = new _EnumApi();
export const BankApi = new _BankApi();
export const BankBranchApi = new _BankBranchApi();
export const DesignationApi = new _DesignationApi();
export const UserNidDetailApi = new _UserNidDetailApi();
export const DepartmentSubjectApi = new _DepartmentSubjectApi();
export const FacultySchoolApi = new _FacultySchoolApi();
export const InstituteOrganizationApi = new _InstituteOrganizationApi();
export const AutomationRuleApi = new _AutomationRuleApi();
export const WorkflowApprovalApi = new _WorkflowApprovalApi();
export const WorkflowStepApi = new _WorkflowStepApi();
export const WorkflowTransitionApi = new _WorkflowTransitionApi();
export const WorkflowBulkTransitionApi = new _WorkflowBulkTransitionApi();
export const WorkflowTransitionAssignmentApi = new _WorkflowTransitionAssignmentApi();
export const ServiceMappingApi = new _ServiceMappingApi();
export const OwnerShipTypeApi = new _OwnerShipTypeApi();
export const PaymentApi = new _PaymentApi();
export const CorePaymentHistoryApi = new _CorePaymentHistoryApi();
export const EducationalInstituteApi = new _EducationalInstituteApi();
//Util Server
export const NotificationApi = new _NotificationApi();

//Grant Server
export const FellowshipTypeApi = new _FellowshipTypeApi();
export const FellowshipAllowanceHeadApi = new _FellowshipAllowanceHeadApi();
export const FellowshipAllowanceRateApi = new _FellowshipAllowanceRateApi();
export const FellowshipGroupApi = new _FellowshipGroupApi();
export const StudyTypeNameApi = new _StudyTypeNameApi();
export const CircularApi = new _CircularApi();
export const EligibleCriteriaApi = new _EligibleCriteriaApi();
export const SelectionCriteriaApi = new _SelectionCriteriaApi();
export const ApplicationTypeApi = new _ApplicationTypeApi();
export const ItemTypeApi = new _ItemTypeApi();
export const StudyTypeApi = new _StudyTypeApi();
export const AttachmentTypeApi = new _AttachmentTypeApi();
export const MarkDistributionApi = new _MarkDistributionApi();
export const CommitteeApi = new _CommitteeApi();
export const CommitteeMemberInfoApi = new _CommitteeMemberInfoApi();
export const FellowshipCategoryApi = new _FellowshipCategoryApi();
export const PriorityProjectApi = new _PriorityProjectApi();
export const SpecialAllocationRateApi = new _SpecialAllocationRateApi();
export const ApplicationFeeApi = new _ApplicationFeeApi();
export const SpecialResearchGrantApplicationApi = new _SpecialResearchGrantApplicationApi();
export const RAndDProjectApi = new _RAndDProjectApi();
export const InternshipThesisApplicationApi = new _InternshipThesisApplicationApi();
export const ProgressReportApi = new _ProgressReportApi();
export const BillSubmissionApi = new _BillSubmissionApi();
export const AgreementSignApi = new _AgreementSignApi();
export const FellowshipCancellationApi = new _FellowshipCancellationApi();
export const SponsorshipLetterApi = new _SponsorshipLetterApi();
export const WorkflowTransitionGrantApi = new _WorkflowTransitionGrantApi();
export const SynopsisOfResearchProjectApi = new _SynopsisOfResearchProjectApi();
export const SynopsisOfResearchProjectRnDMoSTApi = new _SynopsisOfResearchProjectRnDMoSTApi();

// export const DepartmentSubjectApi = new _DepartmentSubjectApi();
export const ApplicationApi = new _ApplicationApi();
export const BSTFTApplicationApi = new _BSTFTApplicationApi();
export const NSTFellowshipApplicationApi = new _NSTFellowshipApplicationApi();
export const ApplicantProfileApi = new _ApplicantProfileApi();
export const ApplicantProfileEducationalQualificationApi = new _ApplicantProfileEducationalQualificationApi();
export const ApplicantProfilePreviousExperienceApi = new _ApplicantProfilePreviousExperienceApi();
export const ApplicantProfileAwardsScholarshipApi = new _ApplicantProfileAwardsScholarshipApi();
export const ApplicantProfilePublicationApi = new _ApplicantProfilePublicationApi();
export const ApplicantProfileTrainingSeminarCourseApi = new _ApplicantProfileTrainingSeminarCourseApi();
export const ApplicantProfileAttachmentApi = new _ApplicantProfileAttachmentApi();

export const CommitteeTypeApi = new _CommitteeTypeApi();
export const ResearchCategoryAreaApi = new _ResearchCategoryAreaApi();
export const ScientificOrgApi = new _ScientificOrgApi();
export const NonGovtInstituteApplicationApi = new _NonGovtInstituteApplicationApi();
export const RAndDMOSTApi = new _RAndDMOSTApi();
export const ResearchFellowshipApplicationApi = new _ResearchFellowshipApplicationApi();
export const ExportGrantApi = new _ExportGrantApi();
export const ImportGrantApi = new _ImportGrantApi();
export const ReportGrantApi = new _ReportGrantApi();
export const FinalSelectionApi = new _FinalSelectionApi();
export const UserPanelApi = new _UserPanelApi();
export const UserApplicationApi = new _UserApplicationApi();
export const UserPanelSummaryApi = new _UserPanelSummaryApi();
export const UserApplicationBillInfoApi = new _UserApplicationBillInfoApi();
export const BillCirculationApi = new _BillCirculationApi();
export const ResearcherProfileApi = new _ResearcherProfileApi();
export const PreviousApprovedProjectApi = new _PreviousApprovedProjectApi();
export const RnDPreviousProjectApi = new _RnDPreviousProjectApi();
export const ResearcherConsentApi = new _ResearcherConsentApi();
export const DepartmentHeadRecommendationApi = new _DepartmentHeadRecommendationApi();
export const ExamApi = new _ExamApi();
export const AIResearcherChangeRequestApi = new _AIResearcherChangeRequestApi();
export const CompletionCertificateApi = new _CompletionCertificateApi();

// License.
export const LicenseUserApplicationApi = new _LicenseUserApplicationApi();
export const ScienceClubApplicationApi = new _ScienceClubApplicationApi();
export const SurrenderTransferApplicationApi = new _SurrenderTransferApplicationApi();
export const ImportExportPermitApi = new _ImportExportPermitApi();
export const LicenseApplicationAApi = new _LicenseApplicationAApi();
export const LicenseApplicationCApi = new _LicenseApplicationCApi();
export const LicenseApplicationEApi = new _LicenseApplicationEApi();
export const LicenseApplicationRcoApi = new _LicenseApplicationRcoApi();
export const LicenseNocApplicationApi = new _LicenseNocApplicationApi();
export const UserApplicationCountApi = new _UserApplicationCountApi();
export const LicenseUserPanelApi = new _LicenseUserPanelApi();
export const LicensePermitsApi = new _LicensePermitsApi();
export const RcoAnnualReportApi = new _RcoAnnualReportApi();
export const LicenseApplicationDApi = new _LicenseApplicationDApi();

// Research Server
export const JournalArticleApi = new _JournalArticleApi();
export const JournalArticleApplicationApi = new _JournalArticleApplicationApi();
export const ResearcherNetworkApi = new _ResearcherNetworkApi();
export const WebinarExpressionOfInterestApi = new _WebinarExpressionOfInterestApi();
export const WebinarRegistrationApi = new _WebinarRegistrationApi();
export const ResearchFeildApi = new _ResearchFeildApi();
export const ResearchLevelApi = new _ResearchLevelApi();
export const JournalArticleApplicationInfoApi = new _JournalArticleApplicationInfoApi();
export const ResearchProfileApi = new _ResearchProfileApi();
export const JournalNameApi = new _JournalNameApi();

// CMS Server
export const PageApi = new _PageApi();
export const BlocksApi = new _BlocksApi();
export const MenuApi = new _MenuApi();
export const ServicesApi = new _ServicesApi();
export const MediaApi = new _MediaApi();
export const CategoriesApi = new _CategoriesApi();

// Library
export const LibraryApi = new _LibraryApi();
export const LibraryMemberRegistrationApi = new _LibraryMemberRegistrationApi();
export const LibraryBookReservationApi = new _LibraryBookReservationApi();
export const LibraryMemberDashboardApi = new _LibraryMemberDashboardApi();
export const BookIssueApi = new _BookIssueApi();
export const LibraryMemberReminderApi = new _LibraryMemberReminderApi();

// Process Lease
export const ProcessLeaseApplicationApi = new _ProcessLeaseApplicationApi();
export const RequestApplicationApi = new _RequestApplicationApi();
export const TemplatesApi = new _TemplatesApi();
export const IssueLetterApi = new _IssueLetterApi();
export const NoteApi = new _NoteApi();
export const UserDashboardApi = new _UserDashboardApi();
export const PaymentLeaseApi = new _PaymentLeaseApi();
export const PaymentHistoryApi = new _PaymentHistoryApi();
export const PaymentPremiumFeeApi = new _PaymentPremiumFeeApi();
export const PaymentAnnulFeeApi = new _PaymentAnnulFeeApi();
export const PaymentAllFeesApi = new _PaymentAllFeesApi();
export const PaymentInformationApi = new _PaymentInformationApi();
export const ApplicationListInformationApi = new _ApplicationListInformationApi();
export const ProcessItemApi = new _ProcessItemApi();

// Eticket
export const ProgramApi = new _ProgramApi();
export const ProgramTypeApi = new _ProgramTypeApi();
export const TicketRequestApi = new _TicketRequestApi();
export const CounterSalesApi = new _CounterSalesApi();
export const SeatPlanApi = new _SeatPlanApi();
export const SeatLayoutApi = new _SeatLayoutApi();
export const HallSetupApi = new _HallSetupApi();
export const PackageApi = new _PackageApi();
export const PackageItemApi = new _PackageItemApi();
export const ProgramScheduleApi = new _ProgramScheduleApi();
export const TicketSaleApi = new _TicketSaleApi();
export const EticketUserDashboardApi = new _EticketUserDashboardApi();
export const ScienceBusApi = new _ScienceBusApi();
export const TransportApi = new _TransportApi();

// Training
export const ExportTrainingApi = new _ExportTrainingApi();
export const ImportTrainingApi = new _ImportTrainingApi();
export const TrainingCategoryApi = new _TrainingCategoryApi();
export const TrainingSubjectApi = new _TrainingSubjectApi();
export const TrainingTitleApi = new _TrainingTitleApi();
export const TrainerProfileApi = new _TrainerProfileApi();
export const InternalTrainerUserApi = new _InternalTrainerUserApi();
export const EOISubmissionApi = new _EOISubmissionApi();
export const RegistrationInCourseApi = new _RegistrationInCourseApi();
export const RegistrationInECourseApi = new _RegistrationInECourseApi();
export const TrainingCourseScheduleApi = new _TrainingCourseScheduleApi();
export const TraineeQuizQuestionApi = new _TraineeQuizQuestionApi();
export const TraineeQuizApi = new _TraineeQuizApi();
export const TraineeExamMarksApi = new _TraineeExamMarksApi();
export const OlympiadQuizExamAndResultApi = new _OlympiadQuizExamAndResultApi();
export const OlympiadQuizExamAnswerDetailsApi = new _OlympiadQuizExamAnswerDetailsApi();
export const TrainingCourseScheduleDetailApi = new _TrainingCourseScheduleDetailApi();
export const TrainingCourseScheduleExamProcessApi = new _TrainingCourseScheduleExamProcessApi();
export const TrainerEvaluationSetupApi = new _TrainerEvaluationSetupApi();
export const EvaluateTrainerByTraineeApi = new _EvaluateTrainerByTraineeApi();
export const OlympiadQuizApi = new _OlympiadQuizApi();
export const LabTrainingVisitApplicationApi = new _LabTrainingVisitApplicationApi();
export const ECourseAssesmentsApi = new _ECourseAssesmentsApi();
export const ECourseAssesmentQuestionApi = new _ECourseAssesmentQuestionApi();
export const ECourseApi = new _ECourseApi();
export const ECourseAssesmentExamMarksApi = new _ECourseAssesmentExamMarksApi();
export const UserDashboardServiceApi = new _UserDashboardServiceApi();
export const OlympiadQuizAttendanceApi = new _OlympiadQuizAttendanceApi();
export const RegistrationInRCOExamCircularApi = new _RegistrationInRCOExamCircularApi();
// TestConsultancy Server
export const ExportTestConsultancyApi = new _ExportTestConsultancyApi();
export const ImportTestConsultancyApi = new _ImportTestConsultancyApi();
export const InventoryApi = new _InventoryApi();
export const TemplateApi = new _TemplateApi();
export const VatAitApi = new _VatAitApi();
export const MOUApi = new _MOUApi();
export const InvoiceApi = new _InvoiceApi();
export const ServiceApplicationApi = new _ServiceApplicationApi();
export const AttributeApi = new _AttributeApi();
export const AttributeItemApi = new _AttributeItemApi();
export const TestServiceMapApi = new _TestServiceMapApi();
export const LetterApi = new _LetterApi();
export const ReportPreparationApi = new _ReportPreparationApi();
export const ReportDeliveryApi = new _ReportDeliveryApi();
export const TestConsultancyReportApi = new _TestConsultancyReportApi();
export const RefundApi = new _RefundApi();
export const MethodologyApi = new _MethodologyApi();
export const CategoryApi = new _CategoryApi();
export const SubCategoryApi = new _SubCategoryApi();
export const ReceiveLetterApi = new _ReceiveLetterApi();
export const FeeCollectionHistoryApi = new _FeeCollectionHistoryApi();
export const FeeCollectionApplicationFeeApi = new _FeeCollectionApplicationFeeApi();
export const FeeCollectionAllFeesApi = new _FeeCollectionAllFeesApi();
export const FeeCollectionInformationApi = new _FeeCollectionInformationApi();
export const ServiceApplicationListInformationApi = new _ServiceApplicationListInformationApi();
export const UserPanelApplicationApi = new _UserPanelApplicationApi();

// Health
export const DoctorAppointmentApi = new _DoctorAppointmentApi();
export const TestingApplicationApi = new _TestingApplicationApi();
export const RadiotherapyPrescriptionApi = new _RadiotherapyPrescriptionApi();
export const TestPaymentApi = new _TestPaymentApi();
export const TestApplicationPaymentHistoryApi = new _TestApplicationPaymentHistoryApi();
export const HealthApplicationApi = new _HealthApplicationApi();
export const HealthServiceApplicationApi = new _HealthServiceApplicationApi();
export const HealthReportPreparationApi = new _HealthReportPreparationApi();
export const HealthUserDashboardApi = new _HealthUserDashboardApi();

// ACCOMMODATION
export const AllotmentApplicationApi = new _AllotmentApplicationApi();
export const AccommodationTypeApi = new _AccommodationTypeApi();
export const CommonConfigurationTypeApi = new _CommonConfigurationTypeApi();
export const AccommodationUserInvoiceApi = new _AccommodationUserInvoiceApi();
export const AccommodationPaymentApi = new _AccommodationPaymentApi();
export const AccommodationUserPanelApi = new _AccommodationUserPanelApi();
export const ApplyDiscountApi = new _ApplyDiscountApi();
