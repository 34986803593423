import {AxiosPromise} from "axios";
import {CONSTANT_CONFIG} from "../../constants";
import {HttpService} from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.TEST_CONSULTANCY_SERVER_PREFIX}/report`;
const endpoints = {
    getReportInvoiceList: () => `${RESOURCE_ENDPOINT}/invoice-report`,
     getAnalysisReportEndPoint: () => `${RESOURCE_ENDPOINT}/analysis-report`,
    getAnalysisTestResultReportEndPoint: () => `${RESOURCE_ENDPOINT}/analysis-report-test-result`,
    getApplicationStatus: () => `${RESOURCE_ENDPOINT}/application-status`,
}

export default class TextConsultancyReportApi {

    public getInvoiceReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getReportInvoiceList();
        return HttpService.get(url, params, headers);
    }
    public getAnalysisReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getAnalysisReportEndPoint();
        return HttpService.get(url, params, headers);
    }
    public getAnalysisTestResultReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getAnalysisTestResultReportEndPoint();
        return HttpService.get(url, params, headers);
    }
    public getApplicationStatus = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getApplicationStatus();
        return HttpService.get(url, params, headers);
    }
}
