import {AxiosPromise} from "axios";
import {CONSTANT_CONFIG} from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.GRANT_SERVER_PREFIX}/report`
const endpoints = {
    getExampleReport: () => `${RESOURCE_ENDPOINT}/example-report`,
    getCircularInformationReport: () => `${RESOURCE_ENDPOINT}/circular-information-report`,
    getNSTFellowshipApplicantReport: () => `${RESOURCE_ENDPOINT}/nst-fellowship-applicant-report`,
    getNSTFellowshipPreliminarySelectedApplicantReport: () => `${RESOURCE_ENDPOINT}/nst-fellowship-preliminary-selected-applicant-report`,
    getNSTFellowshipMarkDistributionReport: () => `${RESOURCE_ENDPOINT}/nst-fellowship-mark-distribution-report`,
    getSpecialResearchGrantApplicantReport: () => `${RESOURCE_ENDPOINT}/special-research-grant-applicant-report`,
    getSpecialResearchGrantPreliminarySelectedApplicantReport: () => `${RESOURCE_ENDPOINT}/special-research-grant-preliminary-selected-applicant-report`,
    getSpecialResearchGrantMarkDistributionReport: () => `${RESOURCE_ENDPOINT}/special-research-grant-mark-distribution-report`,
    getSpecialResearchGrantApplicantBondReport: () => `${RESOURCE_ENDPOINT}/special-research-grant-applicant-bond-report`,
    getSpecialResearchGrantRecipientReport: () => `${RESOURCE_ENDPOINT}/special-research-grant-recipient-report`,
    getScientificOrgApplicantReport: () => `${RESOURCE_ENDPOINT}/scientific-org-applicant-report`,
    getScientificOrgPreliminarySelectedApplicantReport: () => `${RESOURCE_ENDPOINT}/scientific-org-preliminary-selected-applicant-report`,
    getBSTFApplication: () => `${RESOURCE_ENDPOINT}/bstf-application-report`,
    getBSTFApplicant: () => `${RESOURCE_ENDPOINT}/bstf-applicant-report`,
    getRandDProjectMoSTApplication: () => `${RESOURCE_ENDPOINT}/r-and-d-project-most-application-report`,
    getRandDProjectMoSTApplicant: () => `${RESOURCE_ENDPOINT}/r-and-d-project-most-applicant-report`,
    getPeerReviewCommitteeMember: () => `${RESOURCE_ENDPOINT}/peer-review-committee-member-report`,
    getSpecialResearchGrantApprovedProjectsReport: () => `${RESOURCE_ENDPOINT}/special-research-grant-approved-projects`,
}

export default class ReportGrantApi {
    public getExampleReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getExampleReport();
        return HttpService.get(url, params, headers);
    }
    public getCircularInformationReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getCircularInformationReport();
        return HttpService.get(url, params, headers);
    }
    public getNSTFellowshipApplicantReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getNSTFellowshipApplicantReport();
        return HttpService.get(url, params, headers);
    }
    public getNSTFellowshipPreliminarySelectedApplicantReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getNSTFellowshipPreliminarySelectedApplicantReport();
        return HttpService.get(url, params, headers);
    }
    public getNSTFellowshipMarkDistributionReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getNSTFellowshipMarkDistributionReport();
        return HttpService.get(url, params, headers);
    }
    public getSpecialResearchGrantApplicantReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getSpecialResearchGrantApplicantReport();
        return HttpService.get(url, params, headers);
    }
    public getSpecialResearchGrantPreliminarySelectedApplicantReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getSpecialResearchGrantPreliminarySelectedApplicantReport();
        return HttpService.get(url, params, headers);
    }
    public getSpecialResearchGrantMarkDistributionReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getSpecialResearchGrantMarkDistributionReport();
        return HttpService.get(url, params, headers);
    }
    public getSpecialResearchGrantApplicantBondReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getSpecialResearchGrantApplicantBondReport();
        return HttpService.get(url, params, headers);
    }
    public getSpecialResearchGrantRecipientReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getSpecialResearchGrantRecipientReport();
        return HttpService.get(url, params, headers);
    }
    //
    public getScientificOrgApplicantReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getScientificOrgApplicantReport();
        return HttpService.get(url, params, headers);
    }
    public getScientificOrgPreliminarySelectedApplicantReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getScientificOrgPreliminarySelectedApplicantReport();
        return HttpService.get(url, params, headers);
    }
    //
    public getRandDProjectMoSTApplication = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getRandDProjectMoSTApplication();
        return HttpService.get(url, params, headers);
    }
    public getRandDProjectMoSTApplicant = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getRandDProjectMoSTApplication();
        return HttpService.get(url, params, headers);
    }
    public getPeerReviewCommitteeMember = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getPeerReviewCommitteeMember();
        return HttpService.get(url, params, headers);
    }
    public getBSTFApplication = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getBSTFApplication();
        return HttpService.get(url, params, headers);
    }

    public getBSTFApplicant = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getBSTFApplicant();
        return HttpService.get(url, params, headers);
    }

    public getSpecialResearchGrantApprovedProjectsReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getSpecialResearchGrantApprovedProjectsReport();
        return HttpService.get(url, params, headers);
    }
}
