export enum StatusEnum {
    Active = 1,
    Inactive = 0,
}

export enum ApprovalStatusEnum {
    Pending = 0,
    Accepted = 1,
    Rejected = 2,
}

export enum AccessStatusEnum {
    'Access' = 1,
    'Not Access' = 0,
}
