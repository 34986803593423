import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.ETICKET_SERVER_PREFIX}/eticket-user-dashboard`;
const endpoints = {
    getEticketWiseDataCount: () => `${RESOURCE_ENDPOINT}/eticket-user-data`,
}

export default class EticketUserDashboardApi {

  // public getEticketWiseDataCount = (params = {}, headers = {}): AxiosPromise<any> => {
  //   const url = endpoints.getEticketWiseDataCount();
  //   return HttpService.get(url, params, headers);
  // }

  public getEticketWiseDataCount = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.getEticketWiseDataCount();
    return HttpService.get(url, params, headers);
  }

}
