import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.GRANT_SERVER_PREFIX}/user-panel`;
const endpoints = {
    list: () => `${RESOURCE_ENDPOINT}/completion-certificate`,
};

export default class CompletionCertificateApi {
    public list = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.list();
        return HttpService.post(url, payload, params, headers);
    }
}
