import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../constants";
import { HttpService } from "@/services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.HEALTH_SERVER_PREFIX}/user-panel`;
const endpoints = {
    list: () => `${RESOURCE_ENDPOINT}`,
    getListByUserId: () => `${RESOURCE_ENDPOINT}/application-list`,
    getById: (id: any) => `${RESOURCE_ENDPOINT}/${id}`,
}

export default class ApplicationApi {
    public list = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.list();
        return HttpService.get(url, params, headers);
    }

    public getListByUserId = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getListByUserId();
        return HttpService.get(url, params, headers);
    }

    public getById = (id: any): AxiosPromise<any> => {
        const url = endpoints.getById(id);
        return HttpService.get(url);
    }
}
