import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.LICENSE_SERVER_PREFIX}/fee`;
const endpoints = {
    recalculate: () => `${RESOURCE_ENDPOINT}/recalculate`,
};

export default class LicenseFeeApi {
    public recalculate = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.recalculate();
        return HttpService.post(url, payload, params, headers);
    };
}
